import React from "react"
import { CookiesProvider } from "react-cookie"
import "animate.css"

/** @type { import("gatsby").GatsbySSR["wrapRootElement"] } */
export default ({
  element,
}: {
  element: React.ReactNode
  props: any
}) => (
  <>
    <CookiesProvider>
      {element}
    </CookiesProvider>
  </>
)
