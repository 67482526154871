import React, { useContext } from "react"
import { useStaticQuery, graphql, Link, GatsbyLinkProps } from "gatsby"

export interface State {
  locale?: string
  languages?: Languages
}

interface Language {
  isdefault: boolean
  path: string
  dir: string
  name: string
  locale: string
  tag: string
  uris?: {
    origin: string
    remap: string
  }[]
}

class Languages {
  public languages: Language[]
  constructor(languages: Language[]) {
    this.languages = languages.map(
      ({ isdefault, path, dir, name, locale, tag }) => ({
        isdefault,
        path,
        dir,
        name,
        locale,
        tag,
      })
    )
  }

  public default() {
    return this.languages.find(({ isdefault }) => isdefault)!!
  }

  public locale(code: string) {
    return this.languages.find(({ locale }) => locale === code)
  }

  public alternatives(code: string) {
    return this.languages.filter(({ locale }) => locale !== code)
  }
}

export const useLanguages = () => {
  const {
    site: {
      siteMetadata: { languages },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          languages {
            isdefault
            path
            dir
            name
            locale
            tag
            uris {
              origin
              remap
            }
          }
        }
      }
    }
  `)

  return languages as Language[]
}

export const useDefaultLanguage = () =>
  useLanguages().find(({ isdefault }) => isdefault)!!

export const useLocale = (code: string) =>
  useLanguages().find(({ locale }) => locale === code)

export const i18n = (
  locale: string,
  languages: Languages,
  messages: { [s: string]: string },
  values?: { [s: string]: any }
) => {
  if (messages) {
    const message =
      messages[
        Object.keys(messages || {}).find((l) => l === locale) ||
          languages?.default().locale ||
          `en`
      ]

    return values
      ? message.replace(/{{([\w\d]+)}}/gm, (match, tag) => values[tag] || match)
      : message
  }
}

export const I18n = React.memo(
  ({
    values,
    ...messages
  }: {
    values?: {
      [s: string]: any
    }
    [s: string]: any
  }) => {
    const { locale, languages } = useContext(I18nContext)

    return <>{i18n(locale!, languages!, messages, values)}</>
  }
)

interface CustomGatsbyLinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
  active?: boolean
}

export const I18nLink: React.FC<CustomGatsbyLinkProps> = ({
  children,
  to,
  ...props
}) => {
  const { locale, languages } = useContext(I18nContext)
  const { locale: defaultLocale } = useDefaultLanguage()

  const href = locale === defaultLocale
    ? to
    : `/${locale}${to}`

  return (
    <Link
      to={href}
      {...props}
    >
      {children}
    </Link>
  )
}

export const I18nContext = React.createContext<State>({})

export default ({
  children,
  pageContext: { i18n },
}: {
  children: React.ReactNode
  pageContext: {
    i18n?: {
      locale: string
    }
  }
}) => {
  const langs = new Languages(useLanguages())

  return (
    <I18nContext.Provider
      value={{
        locale: i18n ? i18n.locale : langs.default().locale,
        languages: langs,
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}
