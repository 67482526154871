import React from "react"
import "./toggle.css"

export default ({
  onClick,
  visible,
}: {
  onClick: () => void
  visible: boolean
}) => {
  return (
    <div
      className="site-sidebar__toggle"
      onClick={onClick}
    >
      {visible ? (
        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 5L19 19M5 19L19 5" stroke="currentColor" className="icon-cross" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path></svg>
      ) : (
        <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="5" y1="7" x2="19" y2="7" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line><line x1="5" y1="12" x2="19" y2="12" stroke="#000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line><line x1="5" y1="17" x2="19" y2="17" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></line></svg>
      )}
    </div>
  )
}
