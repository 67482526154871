/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "typeface-open-sans"
import "typeface-playfair-display"
import "./src/scss/index.css"

import WrapPageElement from "./src/gatsby/wrapPageElement"
import WrapRootElement from "./src/gatsby/wrapRootElement"

import { nanoid } from "nanoid"

/** @type { import("gatsby").GatsbyBrowser["wrapPageElement"] } */
export const wrapPageElement = WrapPageElement

/** @type { import("gatsby").GatsbySSR["wrapRootElement"] } */
export const wrapRootElement = WrapRootElement

/** @type { import("gatsby").GatsbyBrowser["onServiceWorkerUpdateReady"] } */
export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `Existe uma nova versão desta aplicação. Carregar a nova versão?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

/** @type { import("gatsby").GatsbyBrowser["registerServiceWorker"] } */
export const registerServiceWorker = () => {
  return true
}

/** @type { import("gatsby").GatsbyBrowser["onClientEntry"] } */
export const onClientEntry = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const applicationServerKey = "BF_OXbz_wKgEAj6AnBzQPMMvgJsugorv6JIzZClm1PISx2xauai_c2MDzSTJCdqrgunixh_fwC7eOcZ9fVFnkG0";

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./))
  {
      window.alert("Por questões de segurança, o Internet Explorer foi descontinuado. Por favor, utilize um browser moderno, por exemplo Microsoft Edge, Chrome ou Firefox.");

      window.stop();
  }

  /**
   * urlBase64ToUint8Array
   *
   * @param {string} base64String a public vavid key
   */
  function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  navigator.serviceWorker.ready.then(registration => {
    if (!localStorage.getItem('client_id')) {
      localStorage.setItem('client_id', nanoid());
    }

    window.swRegistration = registration
    window.swNotificationPermissionAlready = Notification.permission === "granted"
  })

  window.askPermissionForNotifications = () => {
    if (!("Notification" in window)) return
    if (Notification.permission === "granted") return;

    Notification.requestPermission()
    .then((result) => {
      if (result === 'denied') return

      return navigator.serviceWorker.getRegistration().then(registration => {
        return registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: urlBase64ToUint8Array(applicationServerKey)
        })
      })
    })
    .then(subscription => fetch('https://bo.bk.brandfire.pt/wp-json/web-push/v1/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      body: JSON.stringify({
        clientId: localStorage.getItem('client_id'),
        subscription
      })
    }))
    .then((response) => {
      if (response.ok) {
        response.json().then(({user_id}) => localStorage.setItem('server_user_id', user_id));

        navigator.serviceWorker.getRegistration().then(registration => {
            registration.showNotification('Brandfire', {
            body: 'Obrigado pela sua subscrição.',
            icon: '/images/logo-circle.png',
          })
        })
      }
    })
    .catch(error => {
      console.error("error getting push permission", error);
    });
  }
}

/** @type { import("gatsby").GatsbyBrowser["onClientEntry"] } */
export const onRouteUpdate = () => {
  const imageWrappers = document.querySelectorAll(`.gatsby-image-wrapper`)

  // https://css-tricks.com/snippets/javascript/loop-queryselectorall-matches/
  // for cross-browser looping through NodeList without polyfills
  for (let i = 0; i < imageWrappers.length; i++) {
    const imageWrapper = imageWrappers[i]
    const imageElement = imageWrapper.querySelector(`picture img`)

    const onImageComplete = () => {
      imageElement.classList.add('image-is-loaded')
    }

    if (imageElement && imageElement.complete) {
      onImageComplete()
    }
  }

  document.body.setAttribute('data-route', window.location.pathname)
}
