import React from "react"
import { Link } from "gatsby"

export default (props: {
  to?: string
  title: string
  onClick?: () => void
  children?: React.ReactNode
  isSubmenuOpen?: boolean
}) => {
  const {
    to,
    title,
    children,
    onClick = () => {},
    isSubmenuOpen = false,
  } = props

  const [open, setOpen] = React.useState(isSubmenuOpen)

  React.useEffect(() => {
    setOpen(isSubmenuOpen)
  }, [
    isSubmenuOpen
  ])

  return <>
    {to && <Link to={to} onClick={onClick} {...props}>
      {title}
    </Link>}
    {children && <a {...props} onClick={() => setOpen(true)} >
      {title}
      <nav className={open ? '' : 'is-visually-hidden'}>
        {children}
      </nav>
    </a>}
  </>
}
