import React from "react"
import CookieConsent from "~/components/CookieConsent"
import I18n from "~/components/I18n"
import { Helmet } from "react-helmet"
import Wrapper from "~/components/Wrapper"

const favicons = [
  { rel: "icon", type: "image/png", sizes: "16x16", href: "/images/logo-circle-16.png" },
  { rel: "icon", type: "image/png", sizes: "32x32", href: "/images/logo-circle-32.png" },
  { rel: "shortcut icon", type: "image/png", href: "/images/logo-circle.png" },
];

/** @type { import("gatsby").GatsbySSR["wrapPageElement"] } */
export default ({
  element,
  props,
}: {
  element: React.ReactNode
  props: any
}) => (
    <>
      <I18n {...props}>
        <Helmet>
          {favicons.map(({
            rel, type, sizes, href
          }, i) => <link
            key={i}
            rel={rel}
            type={type}
            sizes={sizes}
            href={href}
          ></link>)}
        </Helmet>
        <Wrapper>{element}</Wrapper>
        {<CookieConsent />}
      </I18n>
    </>
  )
