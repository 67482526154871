import React from "react"
import { Location } from "@reach/router"

export default ({
  children,
  pathname,
  pathnames = [],
}: {
  children: React.ReactNode
  pathname?: string
  pathnames?: string[]
}) => {
  if (pathname) {
    pathnames = [pathname]
  }

  return (
    <Location>
      {({location}) => (
        pathnames.includes(location.pathname) ? <></> : <>{children}</>
      )}
    </Location>
  )
}
