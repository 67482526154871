import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useCookies } from "react-cookie"
import { CookieSetOptions } from "universal-cookie"
import { useStaticQuery, graphql } from "gatsby"
import "./index.css"

export default () => {
  const [{ cookieConsent }, setCookie] = useCookies(["cookieConsent"])

  const [showed, show] = useState(false)

  const cookieOptions: CookieSetOptions = {
    sameSite: "lax",
    secure: true,
    path: "/",
    maxAge: 86400 * 90,
  }

  const onAccept = () => {
    setCookie(
      "cookieConsent",
      {
        analytical: true,
      },
      cookieOptions
    )
  }

  const onRefuse = () => {
    setCookie(
      "cookieConsent",
      {
        analytical: false,
      },
      cookieOptions
    )
  }

  const {
    site: {
      siteMetadata: {
        analytics: { ua },
      },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          analytics {
            ua
          }
        }
      }
    }
  `)

  useEffect(() => {
    setTimeout(() => show(true), 2000)
  })

  return (
    <>
      {cookieConsent && cookieConsent.analytical && (
        <Helmet defer={false}>
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${ua}`}
          ></script>
          <script
            async
            nonce="DhcnhD3khTMePgXw"
          >{`window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', '${ua}');`}</script>
          <script
            async
            defer
            nonce="DhcnhD3khTMePgXw"
            data-website-id="40779a6b-1d2e-4551-8938-3dc052fb5ca5"
            src="https://analytics.moveyourdigital.dev/umami.js"
          />
        </Helmet>
      )}

      {showed && cookieConsent === undefined && (
        <div className="cookie-content__container">
          <div className="">
            Este website usa cookies para recolher dados analíticos. Dados pessoais não são processados.
          </div>
          <div className="cookie-content__actions">
              <button onClick={onAccept}>Permitir</button>
              &nbsp;
              <button onClick={onRefuse}>Recusar</button>
            </div>
        </div>
      )}
    </>
  )
}
