import React, { SyntheticEvent, useContext, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
// @ts-ignore
import addToMailchimp from "gatsby-plugin-mailchimp";
import HideIfPath from "../HideIfPath"
import { Location } from "@reach/router"
import MenuLink from "./Link"

// @ts-ignore
import Logo from "~/images/Brand/logo.svg"
// @ts-ignore
import Facebook from "~/images/Icons/icon-facebook.svg"
// @ts-ignore
import Instagram from "~/images/Icons/icon-instagram.svg"
// @ts-ignore
import LinkedIn from "~/images/Icons/icon-linkedin.svg"
import { I18nContext, I18nLink, useDefaultLanguage } from "../I18n";

export default ({
  setVisibility
}: {
  setVisibility: (isVisible: boolean) => void
}) => {
  const { locale } = useContext(I18nContext)
  const { locale: defaultLocale } = useDefaultLanguage()

  const closeSidebar = () => setTimeout(() => setVisibility(false), 5000)

  const {
    allWpMenuItem: {
      nodes: menu,
    },
  } = useStaticQuery(graphql`
    query MainMenu {
      allWpMenuItem(
        filter: {locations: {in: [MAIN, MAIN___EN]}}
        sort: {order: ASC, fields: order}
      ) {
        nodes {
          id
          path
          label
          title
          target
          locations
          cssClasses
          linkRelationship
        }
      }
    }
  `) as {
    allWpMenuItem: {
      nodes: {
        id: string
        path: string
        label: string
        title: string | null
        target: string | null
        locations: string[]
        cssClasses: string[]
        linkRelationship: string | null
      }[]
    }
  }

  const [subscription, setSubscription] = useState<{
    result: "loading" | "success" | "error"
    msg?: string
  } | undefined>();

  const onSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubscription({
      result: "loading"
    });

    const email = (e.currentTarget.elements.namedItem('email') as HTMLInputElement).value;

    try {
      const result = await addToMailchimp(email);
      setSubscription(result);

    } catch (e) {
      console.error(e);

      setSubscription({
        result: "error",
        msg: "Please, try again later.",
      });
    }
  }

  const activeMenuLocation = defaultLocale === locale
    ? "MAIN" : `MAIN___${locale?.toUpperCase()}`

  return (
    <aside>
      <header className="is-tiny">
        <I18nLink to="/" className="logo sidebar-logo">
          <Logo role="banner" width="100%" />
        </I18nLink>
      </header>

      <nav role="navigation">
        {menu
        .filter(({locations}) => locations.includes(activeMenuLocation))
        .map(({
          id, path, label
        }) => {
          return (
            <MenuLink
              key={id}
              to={path}
              title={label}
              onClick={closeSidebar}
            />
          )
        })}
      </nav>

      <HideIfPath pathnames={["/", "/en", "/en/"]}>
        <section className="is-fluid grid-area-newsletter">
          <h3 className="is-size-4">Newsletter</h3>
          <form onSubmit={onSubmit}>
            <label className="is-visually-hidden">E-mail</label>
            <input type="email" name="email" />
            {subscription?.result === "error" && (
              <div className="is-invalid has-half-margin-bottom"
                dangerouslySetInnerHTML={{ __html: subscription.msg || '' }}
              />
            )}
            {subscription?.result === "success" && (
              <div className="is-success has-half-margin-bottom">
                {subscription.msg}
              </div>
            )}
            <button type="submit" className="is-uppercase umami--click--newsletter-subscribe" disabled={
              subscription?.result === "loading"
            }>Subscribe</button>
          </form>
          <script id="mcjs">{`!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/4e53a8a2295b326bc7926673e/7fa521f3da24ab9aa87733c39.js");`}</script>
        </section>
      </HideIfPath>

        <footer className="sidebar-footer">
          <HideIfPath pathnames={["/", "/en", "/en/"]}>
            <div className="social-links">
              <a title="Facebook" href="https://www.facebook.com/brandfire.pt" target="_blank" rel="nofollow noreferrer noopener external" className="is-smaller is-rounded is-borderless has-half-margin-right">
                <Facebook fill="currentColor" />
              </a>

              <a title="Instagram" href="https://www.instagram.com/brandfire_pt/" target="_blank" rel="nofollow noreferrer noopener external" className="is-smaller is-rounded is-borderless has-half-margin-right">
                <Instagram fill="currentColor" />
              </a>

              <a title="LinkedIn" href="https://www.linkedin.com/company/brandfire-agency" target="_blank" rel="nofollow noreferrer noopener external" className="is-smaller is-rounded is-borderless has-half-margin-right">
                <LinkedIn fill="currentColor" />
              </a>
            </div>
          </HideIfPath>

        <div className="nav-language">
          <Location>
            {({ location }) => {
              const isEn = location.pathname.split("/").filter(v => !!v).at(0) === "en"
              return <a href={
                isEn ? "/" : "/en"
              } className="nav-language_item">
                {isEn ? "Português" : "English"}
              </a>
            }}
          </Location>
        </div>
        </footer>
    </aside>
  )
}
