import React from "react"
import ReactSidebar from "react-sidebar"
import Sidebar from "../Sidebar"
import Toggle from "../Sidebar/Toggle"
import AOS from "aos"
import "aos/dist/aos.css"

export default ({
  children
}: {
  children: React.ReactNode
}) => {
  const mql = window.matchMedia(`(min-width: 72rem)`)

  const [visible, setVisibility] = React.useState(false)
  const [docked, setIsDocked] = React.useState(mql.matches)

  React.useEffect(() => {
    mql.addListener(() => setIsDocked(mql.matches))
  })

  React.useEffect(() => {
    setVisibility(["/", "/en", "/en/"].includes(window.location.pathname))
  }, [
    window.location.pathname
  ])


  React.useEffect(() => {
    AOS.init({ once: true });
  }, [

  ])

  return (
    <ReactSidebar
      sidebar={<Sidebar setVisibility={setVisibility} />}
      open={visible}
      docked={docked}
      onSetOpen={setVisibility}
      shadow={false}
      sidebarClassName={`sidebar`}
      contentClassName={`content`}
      overlayClassName={`overlay`}
    >
      <noscript>
        <style type="text/css">{`
          [data-aos] {
            opacity: 1 !important;
            transform: translate(0) scale(1) !important;
          }
        `}</style>
      </noscript>
      <main>{children}</main>
      <Toggle
        visible={visible}
        onClick={() => {
          setVisibility(!visible)
        }}
      />
    </ReactSidebar>
  );
}
